.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
}

@font-face {
  font-family: 'Pixel';
  src: url('fonts/Pixel-LCD-7.woff') format('woff');
}

.text-white {
  color: white;
}

.tetrisWrapper {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: url('./images/bg.png') #000;
  background-size: cover;
}

.tetris {
  display: flex;
  align-items: flex-start;
  padding: 40px;
  margin: 0 auto;
  max-width: 900px;
}

.tetris aside {
  width: 100%;
  max-width: 200px;
  display: block;
  padding: 0 20px;
}

.displayComponent {
  box-sizing: border-box;
  display: flex;
  align-items: center; 
  margin: 0 0 20px 0;
  padding: 20px;
  border: 4px solid #333;
  min-height: 30px;
  width: 100%;
  border-radius: 20px;
  color: #999;
  background: #000;
  font-family: Pixel, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}

.gameBtn {
  box-sizing: border-box;
  margin: 0 0 20px 0;
  padding: 20px;
  min-height: 30px;
  width: 100%;
  border-radius: 20px;
  border: none;
  color: white;
  background: #333;
  font-family: Pixel, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
}

.stageComponent {
  display: grid;
  grid-template-rows: repeat(20, calc(25vw / 12));
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1px;
  border: 2px solid #333;
  width: 100%;
  max-width: 25vw;
  background: #111;
}

table {
  color: white;
  margin: 2rem auto;
  border: 1px solid rgba(255,255,255,0.3);
  width: 300px;
  border-collapse: collapse;
}

.tbl-header {
  background-color: rgba(255,255,255,0.3);
 }

th { 
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

td {
  padding: 15px;
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  color: #fff;
  border-bottom: solid 1px rgba(255,255,255,0.1);

}

.gameTypeBtnContainer {
  width: 300px;
  margin: auto;
}